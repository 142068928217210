import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import { fetchRequest } from '@tk/utilities/tk.fetch';
import render from '@tk/utilities/tk.render';

interface Viewport {
    element: HTMLElement | null;
    clickClassName: string | null;
    hasDataClassName: string | null;
    nothingFoundClassName: string | null;
}

interface SearchResponse {
    success: boolean;
    dataAsHtml: string;
}

export default class KDMZSearch extends TKCustomElementFactory {
    searchTermReplace!: string | null;
    viewport!: Viewport;
    fieldElement!: HTMLInputElement | null;
    resetButton!: HTMLButtonElement | null;
    hasValueClassName!: string | null;
    hasClickClassName!: string | null;
    isLoadingClassName!: string | null;
    hasDataClassName!: string | null;
    nothingFoundClassName!: string | null;
    nothingFoundText!: string | null;
    activeClassName!: string | null;
    url!: string | null;
    initialURL!: string | null;
    headerWrapper!: HTMLElement | null;
    resultWrapper!: HTMLElement | null;
    filterResultWrapper!: HTMLElement | null;
    enterClickSelector!: string | null;
    hasSuggest!: boolean;
    suggestTextField!: HTMLElement | null;
    modalOptionModel!: string | null;
    timerID: number | null = null;
    initial: boolean = false;

    constructor() {
        super();

        this.viewport = {
            element: document.querySelector('.viewport'),
            clickClassName: this.getAttribute('data-viewport-click-class-name'),
            hasDataClassName: this.getAttribute('data-viewport-has-data-class-name'),
            nothingFoundClassName: this.getAttribute('data-viewport-nothing-found-class-name'),
        };

        this.fieldElement = this.querySelector('input');
        this.resetButton = this.querySelector('button[type="reset"]');
        this.hasValueClassName = this.getAttribute('data-has-value-class-name');
        this.hasClickClassName = this.getAttribute('data-click-class-name');
        this.isLoadingClassName = this.getAttribute('data-is-loading-class-name');
        this.hasDataClassName = this.getAttribute('data-has-data-class-name');
        this.nothingFoundClassName = this.getAttribute('data-nothing-found-class-name');
        this.nothingFoundText = this.getAttribute('data-nothing-found-text');
        this.activeClassName = this.getAttribute('data-active-class-name');
        this.url = this.getAttribute('data-url');
        this.initialURL = this.getAttribute('data-initial-url');
        this.searchTermReplace = this.getAttribute('data-search-term');

        this.headerWrapper = this.closest('[data-header-search]');
        this.resultWrapper = this.querySelector('[data-search-result]');
        this.filterResultWrapper = this.querySelector('[data-filter-result]');

        this.enterClickSelector = this.getAttribute('data-enter-click-element');
        this.hasSuggest = this.hasAttribute('data-has-suggest');
        if (this.hasSuggest) {
            this.suggestTextField = this.querySelector('[data-search-suggest]');
        }

        this.modalOptionModel = this.getAttribute('data-modal-option-model');
    }

    static get observedAttributes() {
        return ['data-reset-field'];
    }

    connectedCallback() {
        if (!this.fieldElement || !this.url) {
            throw new Error('KDMZSearch: Elements are missing!');
            return;
        }
        if (this.hasValueClassName) {
            this.registerInputListener();
            this.registerResetListener();
            this.checkForValue();
        }
        if (this.url) {
            this.registerSearchListener();
        }
        if (this.hasClickClassName) {
            this.registerClickListener();
            this.registerFocusOutListener();
        }

        if (this.enterClickSelector) {
            this.registerEnterClickEvent();
        }
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this.initial = false;
    }

    attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null) {
        if (name === 'data-reset-field' && newValue === 'true') {
            this.resetField();
        }
    }

    registerClickListener() {
        const listener = this.showField.bind(this);
        this.pushListener({
            event: 'click',
            element: this,
            action: listener,
        });
    }

    registerFocusOutListener() {
        if (!this.fieldElement) return;
        const listener = this.hideField.bind(this);
        this.pushListener({
            event: 'focusout',
            element: this.fieldElement,
            action: listener,
        });
    }

    registerInputListener() {
        if (!this.fieldElement) return;
        const listener = this.checkForValue.bind(this);
        this.pushListener({
            event: 'input',
            element: this.fieldElement,
            action: listener,
        });
    }

    registerResetListener() {
        if (!this.resetButton) return;
        const listener = this.resetField.bind(this);
        this.pushListener({
            event: 'click',
            element: this.resetButton,
            action: listener,
        });
    }

    registerSearchListener() {
        if (!this.fieldElement) return;
        const listener = this.search.bind(this);
        this.pushListener({
            event: 'input',
            element: this.fieldElement,
            action: listener,
        });
    }

    registerEnterClickEvent() {
        if (!this.fieldElement) return;
        const listener = this.handleEnterClickListener.bind(this);
        this.pushListener({
            event: 'keypress',
            element: this.fieldElement,
            action: listener,
        });
    }

    checkForValue() {
        if (!this.fieldElement) return;
        if (this.fieldElement.value) {
            this.classList.add(this.hasValueClassName!);
        } else {
            this.classList.remove(this.hasValueClassName!);
            this.classList.remove(this.hasDataClassName!);
            this.classList.remove(this.nothingFoundClassName!);
            this.viewport.element?.classList.remove(this.viewport.hasDataClassName!);
            this.viewport.element?.classList.remove(this.viewport.nothingFoundClassName!);
            this.initial ? this.resetResult() : (this.initial = true);
            this.hasSuggest && (this.suggestTextField!.textContent = '');
        }
    }

    resetField() {
        if (!this.fieldElement || this.fieldElement.value === '') return;

        this.fieldElement.value = '';
        this.checkForValue();
    }

    showField() {
        this.classList.add(this.hasClickClassName!);
        this.viewport.element?.classList.add(this.viewport.clickClassName!);
        this.fieldElement?.focus();
    }

    hideField() {
        if (!this.fieldElement || this.fieldElement.value) return;
        this.classList.remove(this.hasClickClassName!);
        this.viewport.element?.classList.remove(this.viewport.clickClassName!);
    }

    resetResult() {
        if (this.initialURL) {
            fetchRequest({
                requestURL: this.initialURL,
                resolveHandler: this.searchResponse.bind(this),
                rejectHandler: this.errorResponse.bind(this),
            });
        } else {
            this.resultWrapper && (this.resultWrapper.innerHTML = '');
        }
    }

    search() {
        const value = this.fieldElement?.value || '';

        if (value.length < 3) {
            this.classList.remove(this.hasDataClassName!);
            this.classList.remove(this.nothingFoundClassName!);
            this.viewport.element?.classList.remove(this.viewport.hasDataClassName!);
            this.viewport.element?.classList.remove(this.viewport.nothingFoundClassName!);
            this.timerID && clearTimeout(this.timerID);
            return;
        }

        const valueSplitted = value.split(' ');
        let searchTerm = '';

        valueSplitted.forEach((word, index) => {
            searchTerm += this.searchTermReplace!.replaceAll('%1', word);
            if (index < valueSplitted.length - 1) {
                searchTerm += ' AND ';
            }
        });

        this.classList.add(this.isLoadingClassName!);
        this.timerID && clearTimeout(this.timerID);
        this.timerID = window.setTimeout(() => {
            const data = {
                searchTerm,
                inputValue: value,
            };

            const searchUrl = new URL(this.initialURL!);
            const params = new URLSearchParams({ query: value });
            searchUrl.search = params.toString();

            fetchRequest({
                requestURL: searchUrl.toString(),
                payload: data,
                resolveHandler: this.searchResponse.bind(this),
                rejectHandler: this.errorResponse.bind(this),
            });
        }, 200);
    }

    searchResponse(response: SearchResponse) {
        if (response && response.success) {
            const html = render(response.dataAsHtml);
            this.resultWrapper!.innerHTML = html.innerHTML;
            this.classList.remove(this.isLoadingClassName!);
            this.classList.add(this.hasDataClassName!);
            this.classList.remove(this.nothingFoundClassName!);
            this.viewport.element?.classList.remove(this.viewport.nothingFoundClassName!);
            this.viewport.element?.classList.add(this.viewport.hasDataClassName!);

            if (this.enterClickSelector) {
                const formFields = this.resultWrapper!.querySelectorAll('input, textarea');
                formFields.forEach((field) => {
                    this.pushListener({
                        event: 'keydown',
                        element: field,
                        action: this.handleEnterClickListener.bind(this),
                    });
                });
            }

            if (this.hasSuggest) {
                const suggestElement = html.querySelector('[data-search-result-suggest]');
                const suggest = suggestElement?.textContent || '';
                this.suggestTextField!.textContent = suggest;
            }
        }
    }

    errorResponse() {
        this.classList.remove(this.isLoadingClassName!);
        this.classList.add(this.nothingFoundClassName!);
        this.viewport.element?.classList.add(this.viewport.nothingFoundClassName!);

        this.resultWrapper!.innerHTML = `<div data-no-serach-result-found class="search--nothing-found-area">
            <span>${this.nothingFoundText}</span>
        </div>`;
    }

    handleEnterClickListener(event: KeyboardEvent) {
        if (event.key !== 'Enter') return;
        const basketButton = this.querySelector<HTMLElement>(this.enterClickSelector!);
        basketButton && basketButton.click();
        this.fieldElement?.focus();
    }
}
