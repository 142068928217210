/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
/* eslint-disable max-len */
import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import { fetchRequest } from '@tk/utilities/tk.fetch';

export default class KDMZAsyncFormResultlist extends TKCustomElementFactory {
    inputField?: HTMLInputElement;
    targetContainer?: HTMLElement;
    formular?: HTMLFormElement;
    url: string;

    constructor() {
        super();
        this.inputField = this.querySelector('#sapdebi-find') || undefined;
        this.targetContainer = this.querySelector('[data-async-form-resultlist-target]') || undefined;
        this.formular = this.querySelector('form') || undefined;
        this.url = this.formular?.getAttribute('data-url') || '';
    }

    connectedCallback(): void {
        if (!this.formular || !this.targetContainer) throw new Error('KDMZAsyncFormResultlist: something is missing!');
        this.registerEventListener();
    }

    registerEventListener() {
        this.pushListener({
            event: 'submit',
            element: this.formular!,
            action: this.formSearch.bind(this),
        });
    }

    formSearch(event : Event): void {
        event.preventDefault();

        if (this.inputField && this.inputField.value !== '') {
            const payload = {
                inputField: this.inputField.value,
            };
            fetchRequest({
                requestURL: this.url!,
                payload,
                resolveHandler: this.resultlistInsert.bind(this),
            });
        }
    }

    resultlistInsert(response) {
        if (this.targetContainer) {
            this.targetContainer.innerHTML = response.dataAsHtml;
        }
    }
}
