import TKFormElement from '@tk/controls/tk.form.element';

export default class KDMZFormElement extends TKFormElement {
    select?: HTMLSelectElement;
    checkbox?: HTMLInputElement;

    constructor() {
        super();

        this.select = this.querySelector('select') as HTMLSelectElement;
        this.checkbox = this.querySelector('input[type="checkbox"]') as HTMLInputElement;
    }

    connectedCallback() {
        super.connectedCallback();
        const onSelectChange = this.handleSelectChange.bind(this);
        const onCheckboxChange = this.handleCheckboxChange.bind(this);

        if (this.select) {
            this.pushListener({ event: 'change', element: this.select, action: onSelectChange });
            this.handleSelectChange();
        }

        if (this.checkbox) {
            this.pushListener({ event: 'change', element: this.checkbox, action: onCheckboxChange });
            // this.handleCheckboxChange();
        }
    }

    /**
     * Handles changes to the select element.
     * Toggles the 'tk-form-select--filled' class based on the select element's value.
     */
    handleSelectChange(): void {
        if (!this.select) return;
        this.classList.toggle('tk-form-select--filled', this.select.value !== '');
    }

    /**
     * Handles changes to the checkbox element.
     */
    handleCheckboxChange(): void {
        if (!this.checkbox) return;
        if (this.checkbox.hasAttribute('data-checkbox-toggle-master')) {
            this.toggleAllCheckboxes(this.checkbox, this.checkbox.checked);
        }
        if (this.checkbox.hasAttribute('data-checkbox-toggle')) {
            this.toggleMasterCheckbox(this.checkbox);
        }
    }

    toggleAllCheckboxes(toggler : HTMLInputElement, status : boolean): void {
        const allCheckboxes : NodeListOf<HTMLInputElement> = document.querySelectorAll('input[data-checkbox-toggle]');
        Array.from(allCheckboxes).forEach((box) => {
            box.checked = status;
        });
    }

    toggleMasterCheckbox(box : HTMLInputElement): void {
        const masterCheckbox : HTMLInputElement = document.querySelector('input[data-checkbox-toggle-master]')!;
        const isChecked : boolean = box.checked;
        if (!isChecked) {
            masterCheckbox.checked = false;
        }
    }
}
