/* eslint-disable max-len */
import { fetchRequest } from '@tk/utilities/tk.fetch';
import { RectangleItem, isOutsideShapes } from '@tk/utilities/tk.shape.detector';
import TKViewport from '@tk/basiscs/tk.viewport';
import TKMinibasket from '@tk/components/tk.minibasket';
import render from '@tk/utilities/tk.render';

export default class KDMZMinibasket extends TKMinibasket {
    header?: HTMLElement;
    subheader?: HTMLElement;
    miniBasketHeader?: HTMLElement;

    constructor() {
        super();
        this.header = this.closest('.viewport')?.querySelector('[data-tk-header]') || undefined;
        this.subheader = this.closest('.viewport')?.querySelector('[data-tk-header-subheader]') || undefined;
        this.miniBasketHeader = this.querySelector('.tk-minibasket__header') || undefined;
    }

    registerCloseListener(): void {
        const handleClose = this.close.bind(this);
        this.pushListener({ event: 'click', element: window, action: handleClose });
        if (this.miniBasketHeader) {
            this.pushListener({ event: 'click', element: this.miniBasketHeader, action: handleClose });
        }
    }

    open() {
        document.body.classList.add('body--fixed');
        document.body.insertAdjacentElement('beforeend', this.overlay!);
        this.overlay!.classList.add(this.openClassName);
        this.alignOverlayToButton();
        !this.isDataFetched && this.fetchAll();
        this.dispatchEvent(this.openedEvent);
        this.overlay!.style.height = `${this.overlay!.clientHeight.toString()}px`;
    }

    close(event: MouseEvent) {
        const targetElement : HTMLElement = event.target as HTMLElement;
        if (!this.overlay || targetElement!.closest('[data-tk-action="change"]')) return;
        const headerButtonRect = this.headerButton!.getBoundingClientRect();
        const overlayRect = this.overlay!.getBoundingClientRect();
        const rectangles = [
            { rectangle: headerButtonRect },
            { rectangle: overlayRect },
        ] as RectangleItem[];
        if (isOutsideShapes(event, rectangles)) {
            this.overlay!.classList.remove(this.openClassName);
            this.overlay!.classList.remove('tk-minibasket__overlay--oopen');
            this.dispatchEvent(this.closedEvent);
            this.removeListener(window);
            document.body.classList.remove('body--fixed');
        }
    }

    alignOverlayToButton() {
        const viewport = new TKViewport();
        const currentBreakpoint = viewport.getCurrentBreakpoint(window.innerWidth);

        const { bottom, right } = this.subheader ? this.subheader!.getBoundingClientRect() : this.header!.getBoundingClientRect();
        const { width } = this.overlay!.getBoundingClientRect();
        const distanceToBorder = right - width;

        if (currentBreakpoint === 'l' || currentBreakpoint === 'xl') {
            this.overlay!.style.top = `${bottom}px`;
            this.overlay!.classList.add('tk-minibasket__overlay--oopen');
        } else {
            this.overlay!.style.top = '0px';
            this.overlay!.classList.add('tk-minibasket__overlay--oopen');
        }
    }

    // placeContent(response: TKResponse): void {
    //     if (!response || !response.success) return;
    //     if (!this.contentWrapper) return;
    //     const items = render<HTMLElement>(response.dataAsHtml);
    //     this.contentWrapper.innerHTML = '';
    //     this.contentWrapper.insertAdjacentHTML('beforeend', items.innerHTML);
    // }

    placeContent(response: TKResponse): void {
        if (!response || !response.success) return;
        if (!this.contentWrapper) return;
        const items = render<HTMLElement>(response.dataAsHtml);
        const currentMiniBasketAccordion = this.contentWrapper.querySelector('[data-tk-basket-list] .tk-tabs-accordion--active');
        if (currentMiniBasketAccordion) {
            // const currentContentWrapper = currentMiniBasketAccordion.querySelector('.tk-tabs-accordion__body') as HTMLElement;
            // if (currentContentWrapper) {
            //     const currentContentWrapperNumber = currentMiniBasketAccordion.dataset.saldocBoid;
            //     const divItem = document.createElement('div');
            //     currentContentWrapper.innerHTML = '';
            //     divItem.insertAdjacentHTML('beforeend', items.innerHTML);
            //     const itemsCurrentWrapper = divItem.querySelector(`[data-saldoc-boid="${currentContentWrapperNumber}"]`);
            //     currentContentWrapper.insertAdjacentHTML('beforeend', itemsCurrentWrapper.innerHTML);
            // }
            const accContent = currentMiniBasketAccordion.querySelector('[data-tk-accordion-content]');
            const asyncUrl = currentMiniBasketAccordion.getAttribute('data-content-url') || undefined;
            if (asyncUrl) {
                const htmlString = '<div class="kdmz-loading-icon"><div></div><div></div><div></div><div></div></div>';
                const loadingElement = document.createElement('div');
                loadingElement.innerHTML = htmlString;
                loadingElement.classList.add('flex', 'flex--justify-center', 'spacer-mb-4', 'spacer-mt-4');
                accContent.append(loadingElement);
                fetchRequest({
                    requestURL: asyncUrl!,
                    resolveHandler: this.handleResponseSuccess.bind(this, asyncUrl!, accContent),
                });
            }
        } else {
            this.contentWrapper.innerHTML = '';
            this.contentWrapper.insertAdjacentHTML('beforeend', items.innerHTML);
        }
    }

    handleResponseSuccess(url: string, accContent: HTMLElement, response: TKResponse): void {
        if (!response || !response.success) return;
        const content = accContent;
        if (!content) return;
        content.innerHTML = response.dataAsHtml;
    }
}
