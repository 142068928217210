import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';

export default class KDMZClipboardButton extends TKCustomElementFactory {
    content: string | null;

    constructor() {
        super();
        this.content = this.getAttribute('data-kdmz-content');
    }

    connectedCallback(): void {
        this.content ? this.registerClickListener() : this.remove();
    }

    registerClickListener() {
        const onElementClicked = this.elementClickHandler.bind(this);
        this.pushListener({ event: 'click', element: this, action: onElementClicked });
    }

    elementClickHandler() {
        navigator.clipboard.writeText(this.content!);
    }
}
