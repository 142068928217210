/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
/* eslint-disable max-len */
import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import { fetchRequest } from '@tk/utilities/tk.fetch';
import { NotificationType, sendNotification } from '@tk/utilities/tk.notification';

export default class KDMZSwitch extends TKCustomElementFactory {
    content?: HTMLElement;
    url: string;
    observedTargetSelector?: string;
    observedTarget?: HTMLElement;
    onlineEvent : Event;

    constructor() {
        super();
        this.content = this.closest('.viewport') || undefined;
        this.url = this.getAttribute('data-url') || '';
        this.observedTargetSelector = this.getAttribute('data-observed-target') || undefined;
        if (this.content && this.observedTargetSelector) {
            this.observedTarget = this.content.querySelector(this.observedTargetSelector) || undefined;
        }
        // Erstelle ein neues Event-Objekt f�r das online-Event
        this.onlineEvent = new CustomEvent('fields-added');
    }

    connectedCallback(): void {
        if (!this.observedTarget) throw new Error('Edit field: Elements are missing!');
        if (this.observedTarget) this.registerInputListener();
    }

    registerInputListener() {
        this.pushListener({
            event: 'input',
            element: this.observedTarget!,
            action: this.switchLayout.bind(this),
        });
    }

    switchLayout(event : Event): void {
        if (!event.target) return;
        const value = event.target ? event.target.value : '';
        const payload = {
            projectValue: value,
        };
        fetchRequest({
            requestURL: this.url!,
            payload,
            resolveHandler: this.handleSuccessResponse.bind(this),
        });
    }

    handleSuccessResponse(response) {
        if (response && response.success) {
            const html = response.dataAsHtml;
            this.innerHTML = html;
            //  JBI Todo: Trigger das online-Event auf dem window-Objekt --> wg. Validierung tk-form-validator
            window.dispatchEvent(this.onlineEvent);
        }
    }

    handleErrorResponse(error) {
        console.error(error);
    }
}
