/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
/* eslint-disable max-len */
import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import { fetchRequest } from '@tk/utilities/tk.fetch';
import { NotificationType, sendNotification } from '@tk/utilities/tk.notification';

export default class KDMZBasketButton extends TKCustomElementFactory {
    quantityField?: HTMLInputElement;
    submitButton?: HTMLButtonElement;
    formular?: HTMLFormElement;
    url: string;

    constructor() {
        super();
        this.quantityField = this.querySelector('[name="input_dim1"]') || undefined;
        this.submitButton = this.querySelector('[type="submit"]') || undefined;
        this.formular = this.querySelector('form') || undefined;
        this.url = this.getAttribute('data-url') || '';
    }

    connectedCallback(): void {
        if (!this.formular) throw new Error('KDMZBasketButton: Formular is missing!');
        this.registerEventListener();
    }

    registerEventListener() {
        this.pushListener({
            event: 'submit',
            element: this.formular!,
            action: this.submitForm.bind(this),
        });
        // this.pushListener({
        //     event: 'click',
        //     element: this.submitButton!,
        //     action: this.submitForm.bind(this),
        // });
    }

    submitForm(event : Event): void {
        const isQuickAdd = this.closest('.tk-quick-order') || undefined;
        if (isQuickAdd) {
            event.preventDefault();
            this.submitFormQuickAddAsync();
        }
        console.log('submit');
    }

    submitFormQuickAddAsync(): void {
        const payload = {
            input_dim1: this.quantityField!.value,
        };
        fetchRequest({
            requestURL: this.url!,
            payload,
            resolveHandler: this.handleSuccessQuickAddResponse.bind(this),
        });
    }

    handleSuccessQuickAddResponse(response) {
        if (response && response.dataAsJson.success) {
            sendNotification(
                NotificationType.SUCCESS,
                response.dataAsJson.message,
            );
        }
    }

    submitFormAsync(): void {
        const payload = {
            input_dim1: this.quantityField!.value,
        };
        fetchRequest({
            requestURL: this.url!,
            payload,
            resolveHandler: this.handleSuccessResponse.bind(this),
        });
    }

    handleSuccessResponse(response) {
        if (response && response.success) {
            const html = response.dataAsHtml;
            this.innerHTML = html;
        }
    }
}
