/* eslint-disable no-plusplus */
/* eslint-disable class-methods-use-this */
/* eslint-disable max-len */
// import { opaccAjaxPlugin } from 'https://cdn10.opacc.net/was/v6.16/src/script/opacc.static.js';
import { fetchRequest } from '@tk/utilities/tk.fetch';
import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';

export default class CustomValidation extends TKCustomElementFactory {
    constructor() {
        super();
        this.doneTypingInterval = 1000;

        this.contentWrapper = this;

        this.attachEventListener();
    }

    /**
     * Attach event listeners.
    */
    attachEventListener() {
        this.form = document.querySelector('.GETLOGING-Formular-1');
        this.userKontoField = document.querySelector('.GETLOGING-Formular-1 #field-userkonto');
        if (this.userKontoField) {
            this.doneTyping(this.doneTypingInterval, this.userKontoField, this.clickCheckButton.bind(this));
        }

        this.contentWrapper.addEventListener('focusout', (e) => {
            if (e.target.closest('.opc-form-field-group-validation')) {
                this.validationGroupsCheck(e.target.closest('.opc-form-field-group-validation'));
            }
        });

        this.contentWrapper.addEventListener('click', (e) => {
            // post market item
            if (e.target.closest('.GETLOGING-Formular-1')) {
                this.form = e.target.closest('.GETLOGING-Formular-1');
                this.userKontoField = this.form.querySelector('#field-userkonto');
                this.checkUserkontoBtn = this.form.querySelector('#check-userkonto');

                if (e.target.closest('[data-question-main]')) {
                    if (this.form.querySelector('#step3Q1-yes').checked) {
                        this.showSteps(['step3Q1']);
                        this.hideSteps(['step3Q1-no1', 'step3Q1-no2', 'step3Q1-no3', 'step3Q1-no4', 'step3Q1-no5']);

                        if (this.form.querySelector('#step3Q2-yes').checked) {
                            // Step 2
                            this.showSteps(['step3Q2']);

                            if (this.form.querySelector('#step3Q3-yes').checked) {
                                // Step 3
                                this.showSteps(['step3Q3']);
                            } else if (!this.form.querySelector('#step3Q3-yes').checked) {
                                this.hideSteps(['step3Q3']);
                            }
                        } else if (!this.form.querySelector('#step3Q2-yes').checked) {
                            this.hideSteps(['step3Q2', 'step3Q3']);
                        }
                    } else if (!this.form.querySelector('#step3Q1-yes').checked) {
                        this.showSteps(['step3Q1-no1', 'step3Q1-no2', 'step3Q1-no3', 'step3Q1-no4', 'step3Q1-no5']);
                        this.hideSteps(['step3Q1', 'step3Q2', 'step3Q3']);
                        this.form.querySelector('button[type="submit"]').disabled = false;
                        this.userKontoField.value = '';
                        this.checkUserkontoBtn.classList.remove('is-valid');
                        this.checkUserkontoBtn.classList.remove('is-invalid');
                        document.querySelector('#field-userkonto-contact').value = '';
                    }
                }

                if (e.target.closest('#check-userkonto')) {
                    // this.checkUserkontoBtn = e.target.closest('.is-materialized').classList.add('is-fixed-materialized');
                    this.validateEmailAdress();
                }
            }
        });
    }

    validateEmailAdress() {
        this.checkUserkontoBtn = document.querySelector('#check-userkonto');
        const { url } = this.checkUserkontoBtn.dataset;
        const data = {
            email: this.userKontoField.value,
        };
        this.checkUserkontoBtn.classList.remove('is-valid');
        this.checkUserkontoBtn.classList.remove('is-invalid');
        // JBI Todo: opaccAjaxPlugin.fetchAsynPostForm(url, data, this.checkUserAccount.bind(this));
        fetchRequest({
            requestURL: url,
            payload: data,
            resolveHandler: this.checkUserAccount.bind(this),
        });
    }

    validationGroupsCheck(group = undefined) {
        let groups;
        if (group) {
            groups = [group];
        } else {
            groups = document.querySelectorAll('.opc-form-field-group-validation');
        }
        const resultArray = [];

        [...groups].forEach((group) => {
            console.log(!group.closest('[data-target].hidden'));
            if (!group.closest('[data-target].hidden')) {
                let fieldsFilled = 0;
                [...group.querySelectorAll('input')].forEach((field) => {
                    if (field.value.length > 0) {
                        fieldsFilled++;
                    }
                });
                if (fieldsFilled === 0) {
                    group.classList.add('is-invalid');
                    resultArray.push({
                        item: group,
                        validation: 'fehler',
                    });
                } else {
                    group.classList.remove('is-invalid');
                }
            }
        });
        return resultArray;
    }

    checkUserAccount(result) {
        if (document.querySelector('#field-userkonto-contact')) {
            document.querySelector('#field-userkonto-contact').value = '';
        }
        if (result.dataAsJson.status === 'valid') {
            this.checkUserkontoBtn.classList.add('is-valid');
            this.checkUserkontoBtn.classList.remove('is-invalid');
            this.checkUserkontoBtn.closest('.opc-row').nextElementSibling.classList.remove('hidden');
            this.form.querySelector('button[type="submit"]').disabled = false;
            if (document.querySelector('#field-userkonto-contact')) {
                document.querySelector('#field-userkonto-contact').value = `${result.addrnr},${result.kpnr}`;
            }
        } else {
            this.form.querySelector('button[type="submit"]').disabled = true;
            this.checkUserkontoBtn.classList.remove('is-valid');
            this.checkUserkontoBtn.classList.add('is-invalid');
            this.checkUserkontoBtn.closest('.opc-row').nextElementSibling.classList.add('hidden');
            this.hideSteps(['step3Q1b', 'step3Q2', 'step3Q3', 'step3Q1-no1', 'step3Q1-no2', 'step3Q1-no3', 'step3Q1-no4', 'step3Q1-no5']);
        }
    }

    validateEmailAdressField() {
        const resultArray = [];
        if (this.userKontoField.hasAttribute('required')) {
            if (!this.checkUserkontoBtn.classList.contains('is-valid')) {
                resultArray.push({
                    item: this.userKontoField,
                    validation: 'Emailadresse existiert nicht',
                });
            }
        }
        return resultArray;
    }

    clickCheckButton() {
        if (this.validateEmail(this.userKontoField.value)) {
            this.checkUserkontoBtn.click();
        // console.log("click");
        } else {
            if (document.querySelector('#field-userkonto-contact')) {
                document.querySelector('#field-userkonto-contact').value = '';
            }
            this.checkUserkontoBtn.classList.remove('is-valid');
            this.checkUserkontoBtn.classList.remove('is-invalid');
        }
    }

    validateEmail(email) {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            );
    }

    showSteps(arr = []) {
        arr.forEach((step) => {
            if (this.form.querySelector(`[data-target="${step}"]`)) {
                this.form.querySelector(`[data-target="${step}"]`).classList.remove('hidden');
                const allInvalid = this.form.querySelector(`[data-target="${step}"]`).querySelectorAll('.is-invalid');
                [...allInvalid].forEach((formField) => {
                    formField.classList.remove('is-invalid');
                    if (formField.querySelector('.form-field-error')) {
                        formField.querySelector('.form-field-error').remove();
                    }
                });

                this.setAllRequired(step);
            }
        });
    }

    hideSteps(arr = []) {
        arr.forEach((step) => {
            if (this.form.querySelector(`[data-target="${step}"]`)) {
                this.form.querySelector(`[data-target="${step}"]`).classList.add('hidden');
                if (this.form.querySelector(`[data-target="${step}"] [id*="-yes"] `)) this.form.querySelector(`[data-target="${step}"] [id*="-yes"] `).checked = false;
                this.removeAllRequired(step);
            }
        });
    }

    setAllRequired(step) {
        const allRequired = this.form.querySelectorAll(`[data-target="${step}"] [data-origin="required"]`);
        Array.from(allRequired).forEach((field) => {
            field.removeAttribute('data-origin');
            field.setAttribute('required', 'required');
        });
    }

    removeAllRequired(step) {
        const allRequired = this.form.querySelectorAll(`[data-target="${step}"] [required]`);
        Array.from(allRequired).forEach((field) => {
            field.removeAttribute('required');
            field.setAttribute('data-origin', 'required');
        });
    }

    /**
     * DoneTyping Event welches auf irgendein DOM-Element gebunden werden kann.
     * @param {int} interval Intervall in Millisekunden bis Event ausgel�st wird.
     * @param {Object} selector DOM Element auf welches das Event gebunden wird.
     * @param {function} perform Funktion, welche nach dem DoneTyping aufgerufen werden soll.
     * @param {function} novalue Funktion, welche aufgerufen werden soll, falls das Input Element geleert wurde.
     */
    doneTyping(interval, selector, perform, novalue = undefined) {
        let typingTimer;
        const doneTypingInterval = interval;

        selector.addEventListener('keyup', () => {
            clearTimeout(typingTimer);
            if (selector.value) {
                typingTimer = setTimeout(perform, doneTypingInterval);
            } else if (novalue) {
                novalue();
            }
        });
    }
}
