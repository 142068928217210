import { RectangleItem, isOutsideShapes } from '@tk/utilities/tk.shape.detector';
import TKViewport from '@tk/basiscs/tk.viewport';
import TKMinibasket from '@tk/components/tk.minibasket';

export default class KDMZMinibasket extends TKMinibasket {
    header?: HTMLElement;
    subheader?: HTMLElement;
    miniBasketHeader?: HTMLElement;

    constructor() {
        super();
        this.header = this.closest('.viewport')?.querySelector('[data-tk-header]') || undefined;
        this.subheader = this.closest('.viewport')?.querySelector('[data-tk-header-subheader]') || undefined;
        this.miniBasketHeader = this.querySelector('.tk-minibasket__header') || undefined;
    }

    registerCloseListener(): void {
        const handleClose = this.close.bind(this);
        this.pushListener({ event: 'click', element: window, action: handleClose });
        if (this.miniBasketHeader) {
            this.pushListener({ event: 'click', element: this.miniBasketHeader, action: handleClose });
        }
    }

    open() {
        document.body.classList.add('body--fixed');
        document.body.insertAdjacentElement('beforeend', this.overlay!);
        this.overlay!.classList.add(this.openClassName);
        this.alignOverlayToButton();
        !this.isDataFetched && this.fetchAll();
        this.dispatchEvent(this.openedEvent);
        this.overlay!.style.height = `${this.overlay!.clientHeight.toString()}px`;
    }

    close(event: MouseEvent) {
        if (!this.overlay) return;
        const headerButtonRect = this.headerButton!.getBoundingClientRect();
        const overlayRect = this.overlay!.getBoundingClientRect();
        const rectangles = [
            { rectangle: headerButtonRect },
            { rectangle: overlayRect },
        ] as RectangleItem[];
        if (isOutsideShapes(event, rectangles)) {
            this.overlay!.classList.remove(this.openClassName);
            this.overlay!.classList.remove('tk-minibasket__overlay--oopen');
            this.dispatchEvent(this.closedEvent);
            this.removeListener(window);
            document.body.classList.remove('body--fixed');
        }
    }

    alignOverlayToButton() {
        const viewport = new TKViewport();
        const currentBreakpoint = viewport.getCurrentBreakpoint(window.innerWidth);

        const { bottom, right } = this.subheader ? this.subheader!.getBoundingClientRect() : this.header!.getBoundingClientRect();
        const { width } = this.overlay!.getBoundingClientRect();
        const distanceToBorder = right - width;

        if (currentBreakpoint === 'l' || currentBreakpoint === 'xl') {
            this.overlay!.style.top = `${bottom}px`;
            this.overlay!.classList.add('tk-minibasket__overlay--oopen');
        } else {
            this.overlay!.style.top = '0px';
            this.overlay!.classList.add('tk-minibasket__overlay--oopen');
        }
    }
}
